( function( ) {
	Vue.transition('fade', {
	    enterClass: 'fadeIn',
	    leaveClass: 'fadeOut'
	});
	Vue.transition('slideDown', {
	    enterClass: 'slideInDown',
	    leaveClass: 'slideOutRight'
	});
	Vue.transition('slideDownUp', {
	    enterClass: 'fadeInDown',
	    leaveClass: 'slideOutUp'
	});
	Vue.transition('slide', {
        enterClass: 'slideInRight',
        leaveClass: 'slideOutLeft'
    });
    Vue.filter('humanDate', function(value) {
	    return moment(value).fromNow();
	});
	Vue.filter('formatDate', function(value) {
		if(value == null || value == undefined){
			return 'NA';
		}
	    return moment(value).format('DD-MMMM-YYYY');
	});
	Vue.filter('previousDate', function(value) {
		if(value == null || value == undefined){
			return 'NA';
		}
	    return moment(value).subtract(1, 'day').format('DD-MMMM-YYYY');
	});
	Vue.filter('formatDateDos', function(value) {
		if(value == null || value == undefined){
			return 'NA';
		}
	    return moment(value).format('DD-MMMM-YYYY');
	});
	Vue.filter('formatDateTres', function(value) {
		if(value == null || value == undefined){
			return 'NA';
		}
	    return moment(value).locale('es').format('MMMM DD[,] YYYY');
	});
	Vue.filter('detailDate', function(value) {
	    return moment(value).format('YY-MM-DD HH:mm');
	});
	Vue.filter('detailDateDos', function(value) {
	    return moment(value).format('MMMM DD [of] YYYY [ at <strong>]HH:mm[</strong>]');
	});
	Vue.filter('year', function(value) {
		if(value == null || value == undefined){
			return 'NA';
		}
		var fecha = moment(value).get('year');
		var hoy = moment().year();
	    return hoy - fecha;
	});
	Vue.filter('estatus', function(value) {
	    if(value == 1) { return '<i class="fa fa-thumbs-up u__green"></i> Active'}
	    if(value == 0) { return '<i class="fa fa-thumbs-down u__red"></i> Suspended'}
	});
	Vue.filter('transportation', function(value) {
	    if(value == 1) { return '<i class="fa fa-check u__green"></i> Yes'}
	    if(value == 0) { return '<i class="fa fa-times u__red"></i> No'}
	});
	Vue.filter('estatusService', function(value) {
	    if(value == 0) { return 'To be Confirmed'}
	    if(value == 1) { return 'For Payment'}
	    if(value == 2) { return 'Confirmed'}
	    if(value == 3) { return 'Canceled'}
	});
	Vue.filter('nullable', function(value) {
	    if(value == null) { return 'NA'}
	    return value;
	});
}) ( );
